import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { UserGuard } from 'src/components/auth-guard/user-guard';
// layouts
import AdminLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { AuthGuard } from 'src/auth/guard';

// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/dashboard/index'));

// 회원 관리
const UserCommonPage = lazy(() => import('src/pages/dashboard/user/common'));
const UserAddCommonPage = lazy(() => import('src/pages/dashboard/user/common-add'));
const UserDetailCommonPage = lazy(() => import('src/pages/dashboard/user/common-detail'));
const UserReservationDetailPage = lazy(() => import('src/pages/dashboard/user/common-reservation-detail'));

const UserCompanyPage = lazy(() => import('src/pages/dashboard/user/company'));
const UserAddCompanyPage = lazy(() => import('src/pages/dashboard/user/company-add'));
const UserDetailCompanyPage = lazy(() => import('src/pages/dashboard/user/company-detail'));

const UserPartnerPage = lazy(() => import('src/pages/dashboard/user/partner'));
const UserAddPartnerPage = lazy(() => import('src/pages/dashboard/user/partner-add'));
const UserDetailPartnerPage = lazy(() => import('src/pages/dashboard/user/partner-detail'));

// 지점 관리
const BranchListPage = lazy(() => import('src/pages/dashboard/branch/list'));
const BranchListDetailPage = lazy(() => import('src/pages/dashboard/branch/list-detail'));
const BranchListAddPage = lazy(() => import('src/pages/dashboard/branch/list-add'));
// const BranchApproveUploadPage = lazy(() => import('src/pages/dashboard/branch/approve-upload'));

// 오피스 관리
const OfficeLockListPage = lazy(() => import('src/pages/dashboard/office/lock-list'));
const OfficeLockDetailView = lazy(() => import('src/pages/dashboard/office/lock-detail'));
const OfficeAccessListPage = lazy(() => import('src/pages/dashboard/office/access-list'));
const OfficeAccessDetailView = lazy(() => import('src/pages/dashboard/office/access-detail'));

// 상품 관리
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductAddPage = lazy(() => import('src/pages/dashboard/product/add'));
const ProductDetailPage = lazy(() => import('src/pages/dashboard/product/detail'));

// 부대시설 관리
const FacilityListPage = lazy(() => import('src/pages/dashboard/facility/list'));

// 예약 주문 관리
const OrderPage = lazy(() => import('src/pages/dashboard/order/OrderPage'));
const OredrUserInfoPage = lazy(() => import('src/pages/dashboard/order/order-user-info'));

// const OrderCommunityPage = lazy(() => import('src/pages/dashboard/orderComponent/community'));
// const OrderShoppingPage = lazy(() => import('src/pages/dashboard/orderComponent/shopping'));

// 크레딧 관리
const CreditChargePage = lazy(() => import('src/pages/dashboard/credit/charge'));
const CreditPaymentPage = lazy(() => import('src/pages/dashboard/credit/payment'));
const CreditRefundPage = lazy(() => import('src/pages/dashboard/credit/refund'));
const CreditRecoveryPage = lazy(() => import('src/pages/dashboard/credit/recovery'));
const CreditChargeDetailPage = lazy(() => import('src/pages/dashboard/credit/charge-detail'));
const CreditRefundDetailPage = lazy(() => import('src/pages/dashboard/credit/refund-detail'));

// 정산 관리
const CalculateHistoryPage = lazy(() => import('src/pages/dashboard/calculate/history'));
const CalculateSettlementDetailPage = lazy(() => import('src/pages/dashboard/calculate/settlement-detail'));
const CalculateHistoryDetailPage = lazy(() => import('src/pages/dashboard/calculate/history-detail'));

// 쿠폰 관리
const CouponListPage = lazy(() => import('src/pages/dashboard/coupon/coupon-list'));
const CouponSettingPage = lazy(() => import('src/pages/dashboard/coupon/coupon-setting'));

// 리뷰 관리
const ReviewListPage = lazy(() => import('src/pages/dashboard/review/list'));
const ReviewSnsPage = lazy(() => import('src/pages/dashboard/review/sns'));
const ReviewListDetailView = lazy(() => import('src/pages/dashboard/review/list-detail'));
const ReviewSnsDetailView = lazy(() => import('src/pages/dashboard/review/sns-detail'));
const ReviewSnsAddView = lazy(() => import('src/pages/dashboard/review/sns-add'));

// 상담문의 관리
const InquiryCompanyPage = lazy(() => import('src/pages/dashboard/inquiry/company'));
const InquiryPartnerPage = lazy(() => import('src/pages/dashboard/inquiry/partner'));

// 홈페이지 관리
const HomepageFaqPage = lazy(() => import('src/pages/dashboard/homepage/faq'));
const HomepageNewsPage = lazy(() => import('src/pages/dashboard/homepage/news'));
const HomepageNewsAddPage = lazy(() => import('src/pages/dashboard/homepage/news-add'));
const HomepageNewsDetailPage = lazy(() => import('src/pages/dashboard/homepage/news-detail'));

// 알림서비스 관리
const AlertListPage = lazy(() => import('src/pages/dashboard/alert/list'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'admin',
    element: (
      <AuthGuard>
        <AdminLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </AdminLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      {
        path: 'user',
        children: [
          { element: <UserCommonPage />, index: true },
          // 일반회원
          { path: 'common', element: <UserCommonPage /> },
          { path: 'common/detail/:id', element: <UserDetailCommonPage /> },
          { path: 'common/add', element: <UserAddCommonPage /> },
          { path: 'common/reservation/detail/:id', element: <UserReservationDetailPage /> },
          // 기업회원
          { path: 'company', element: <UserCompanyPage /> },
          { path: 'company/add', element: <UserAddCompanyPage /> },
          { path: 'company/detail/:id', element: <UserDetailCompanyPage /> },
          { path: 'company/reservation/detail/:id', element: <UserReservationDetailPage /> },
          // 파트너회원
          { path: 'partner', element: <UserPartnerPage /> },
          { path: 'partner/add', element: <UserAddPartnerPage /> },
          { path: 'partner/detail/:id', element: <UserDetailPartnerPage /> },
          { path: 'partner/reservation/detail/:id', element: <UserReservationDetailPage /> },
          { path: 'partner/product/detail/:id', element: <ProductDetailPage /> }
        ]
      },
      {
        path: 'branch',
        children: [
          { element: <BranchListPage />, index: true },
          { path: 'list', element: <BranchListPage /> },
          { path: 'list/detail/:id', element: <BranchListDetailPage /> },
          { path: 'add', element: <BranchListAddPage /> }
          // { path: 'approve/upload', element: <BranchApproveUploadPage /> }
        ]
      },
      {
        path: 'office',
        children: [
          { element: <OfficeLockListPage />, index: true },
          { path: 'lock/manage', element: <OfficeLockListPage /> },
          { path: 'lock/manage/:id', element: <OfficeLockDetailView /> },
          { path: 'access/manage', element: <OfficeAccessListPage /> },
          { path: 'access/manage/:id', element: <OfficeAccessDetailView /> }
        ]
      },
      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: 'add', element: <ProductAddPage /> },
          { path: 'detail/:id', element: <ProductDetailPage /> }
        ]
      },
      {
        path: 'facility',
        children: [{ element: <FacilityListPage />, index: true }]
      },
      {
        path: 'order',
        children: [
          { element: <OrderPage />, index: true },
          { path: 'workation', element: <OrderPage /> },
          { path: 'workation/detail/:id', element: <UserReservationDetailPage /> },
          { path: 'community', element: <OrderPage /> },
          { path: 'community/detail/:id', element: <UserReservationDetailPage /> },
          { path: 'shopping', element: <OrderPage /> },
          { path: 'shopping/detail/:id', element: <UserReservationDetailPage /> },
          { path: 'workation/detail/userinfo/:id/:accessGroupId', element: <OredrUserInfoPage /> }
        ]
      },
      {
        path: 'credit',
        children: [
          { element: <CreditChargePage />, index: true },
          { path: 'charge', element: <CreditChargePage /> },
          { path: 'payment', element: <CreditPaymentPage /> },
          { path: 'refund', element: <CreditRefundPage /> },
          { path: 'recovery', element: <CreditRecoveryPage /> },
          { path: 'charge/detail/:id', element: <CreditChargeDetailPage /> },
          { path: 'refund/detail/:id', element: <CreditRefundDetailPage /> }
        ]
      },
      {
        path: 'calculate',
        children: [
          { element: <CalculateHistoryPage />, index: true },
          { path: 'history', element: <CalculateHistoryPage /> },
          { path: 'history/detail/:id/:date/:pk', element: <CalculateHistoryDetailPage /> },
          { path: 'history/settlement/:id', element: <CalculateSettlementDetailPage /> }
        ]
      },
      {
        path: 'coupon',
        children: [
          { element: <CouponListPage />, index: true },
          { path: 'list', element: <CouponListPage /> },
          { path: 'setting', element: <CouponSettingPage /> }
        ]
      },
      {
        path: 'review',
        children: [
          { element: <ReviewListPage />, index: true },
          { path: 'list', element: <ReviewListPage /> },
          { path: 'sns', element: <ReviewSnsPage /> },
          { path: 'list/:id', element: <ReviewListDetailView /> },
          { path: 'sns/:id', element: <ReviewSnsDetailView /> },
          { path: 'sns/add', element: <ReviewSnsAddView /> }
        ]
      },
      {
        path: 'inquiry',
        children: [
          { element: <InquiryCompanyPage />, index: true },
          { path: 'company', element: <InquiryCompanyPage /> },
          { path: 'partner', element: <InquiryPartnerPage /> }
        ]
      },
      {
        path: 'homepage',
        children: [
          { element: <HomepageFaqPage />, index: true },
          { path: 'faq', element: <HomepageFaqPage /> },
          { path: 'news', element: <HomepageNewsPage /> },
          { path: 'news/add', element: <HomepageNewsAddPage /> },
          { path: 'news/detail/:id', element: <HomepageNewsDetailPage /> }
        ]
      },
      {
        path: 'notification',
        children: [
          { element: <AlertListPage />, index: true },
          { path: 'list', element: <AlertListPage /> }
        ]
      }
    ]
  }
];
